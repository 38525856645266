export const data = [
    {
        "address": "0xb65314DCf28073F49C676295Dc7EC757154FAa9E"
    },
    {
        "address": "0x46470f44aA10676Dc4b20d3EB4CFcF84fbaed1CF"
    },
    {
        "address": "0xEb7E57CDcAa9C43fCf2dD1D3e4376C8f43691C4b"
    },
    {
        "address": "0xBef02A4C1b3Bb8244f0798EAFD063c4350Bd9327"
    },
    {
        "address": "0x4868bb440100fc94d7B0B0F6a2b4bbe2020FaeE2"
    },
    {
        "address": "0x27fE96154BCfb9E7b30d14237CBD52D25Af40e04"
    },
    {
        "address": "0xFd96eB75430C0d1221AdD562f818E2eeE2Ca1dc0"
    },
    {
        "address": "0x699627360095Bb975e027F08a707d8cE08A863C3"
    },
    {
        "address": "0xd2f2c4784A149E429b13226CE59136392506F145"
    },
    {
        "address": "0x8242191BEeF541Cd4F8Fd11D6E051330Cc8feda4"
    },
    {
        "address": "0x3406b3F5313E98A3c81da41A99Aeaa722BEbd0E2"
    },
    {
        "address": "0x3397Ff8dF019aa10958a49302Fa7822CdB737C8C"
    },
    {
        "address": "0xF3AB3433bC895d9523c531866c7ade739B7cB8B2"
    },
    {
        "address": "0x63C21CFf2e73FeE1926bE964EC8A7cC18b7A4653"
    },
    {
        "address": "0xf785f1c6eae1a172d66acad30806E723cD2772a0"
    },
    {
        "address": "0xD7aa90290952B41F04Ef0862B9C9f4e96a769e14"
    },
    {
        "address": "0x3a8894FAb28b9d52e322cBEe2dcf64F8c3A48ecf"
    },
    {
        "address": "0x5407C122285B6730f90Ca18aC81734ea6fb4d1EC"
    },
    {
        "address": "0xd0B1a5fff4F1193Fef776EEB9eAd457452D0274f"
    },
    {
        "address": "0x9Bc24FfE97Aa810BDA0C067dAB66934C1bEBa2c2"
    },
    {
        "address": "0xBa89bB19DA1073A19060596b640AB3aD5461382E"
    },
    {
        "address": "0x12030839a8d58823849d5b24822599dD9Cf8d52f"
    },
    {
        "address": "0x54Ad30A76B4D94cAd2b95f431C5B4046ECEc89Ea"
    },
    {
        "address": "0x9E9d511a608215F36617dc3a318a1C3D7e9Fcb9a"
    },
    {
        "address": "0x6d684359F401c66D3F7FbEf2029869692F0615e3"
    },
    {
        "address": "0xaaFf86C33eA049939fE8D6F35fCF62ba90e39774"
    },
    {
        "address": "0x7179ae02A9383b5dcA59F2CabA19E122AB3ea654"
    },
    {
        "address": "0x01c5C919bF4C233c11023C5772F3039C1763E134"
    },
    {
        "address": "0x9ee03e7F36D34D5a85c7C61f2fb5D08eFAa8666C"
    },
    {
        "address": "0x131AD57aea5008570a76dc81F1457362EBD83dd7"
    },
    {
        "address": "0xE304fBd1C92741Eb1DC1C63ab029681a95FBD54F"
    },
    {
        "address": "0x2c3a42DB8F3E700D2d7d75bE4aCa96F97516b22b"
    },
    {
        "address": "0xd2E4705EbDe3Cf1F0A8dB4bbBCCD9D9653aE1b9B"
    },
    {
        "address": "0x872D1F15414EA27DeDf603C34D741eecF9B61C6E"
    },
    {
        "address": "0x09Fa3EA3A1ADcae4BA6DAf1e4C30301B325E0808"
    },
    {
        "address": "0x3770A7298319e42B2C09b1Dd8d46120DCafCd649"
    },
    {
        "address": "0x7E2793fD4286e1CF84f0EC0cFdc676cee47E82B2"
    },
    {
        "address": "0xA0f39923D226381f649e54Dc50E7E175cF9D756f"
    },
    {
        "address": "0x92BFABC57B375dA06Ef3288a0ED805ff82bD0952"
    },
    {
        "address": "0xd729b2Aa4F46c875160F1380315fB367C709874A"
    },
    {
        "address": "0x5CEe98A8Fb8f62ed59dB2A8527356Fe84AA1d6b5"
    },
    {
        "address": "0xE6A4Ec2ad2f410Ac99981F20Ffb2C6ec10e20bc9"
    },
    {
        "address": "0x7e7c8FbDf8663f2A20A2C3Fb45C96060423cBfAd"
    },
    {
        "address": "0xC587DFBed14771aDF577C0Eb20341924d619DB54"
    },
    {
        "address": "0x58AbC20D3F7eA5F841fffc49c3909ACd35Eb78D7"
    },
    {
        "address": "0x7aBe7bbac43651F1131102471e318906278264ED"
    },
    {
        "address": "0xA6745C77830a0E5d84eFf005E40f2e1d181F0166"
    },
    {
        "address": "0xE855eb39eA7388cA847FC42217debd39821b1F19"
    },
    {
        "address": "0xfc5F11FF56b6120cfEB5fFaB40eF8552dbb3Dcde"
    },
    {
        "address": "0x30f2100110Cc24f216C245760FBD5E4FCe2Ca4A7"
    },
    {
        "address": "0x29623D3D3756c20428EB595a20461aC268C306cE"
    },
    {
        "address": "0xC29c435C0A9b389BCc96022EDF508d7378a95C75"
    },
    {
        "address": "0xa9C9A68148EE30373d67bc4D9DC2c4E73B8e941b"
    },
    {
        "address": "0xddb7642c3011D0c938edAD7a40beaAF041C3C030"
    },
    {
        "address": "0x4B9FB5d4d3C9982961438964e84380631af56B52"
    },
    {
        "address": "0xAf512a103fE83aeD9112799bc6d797fc77973f54"
    },
    {
        "address": "0x28443f893B780b19e745332A19321c507f246F04"
    },
    {
        "address": "0xfe40efAcc619536ac376e7AA4050eD707726603A"
    },
    {
        "address": "0x6eB1Fd922812599FDB89CE97192141216A6C3562"
    },
    {
        "address": "0xd07cc3D9717a7f121b8ED5bD26C95245F608FE8c"
    },
    {
        "address": "0x78Bbe62E7B01c39221D933998E65b2d1e7Fb74Ec"
    },
    {
        "address": "0x883C87Ebb219c2dd4030106F548C53110080Eadd"
    },
    {
        "address": "0xC89F8980d3383417d5A072BdA28D76bC0025F8D7"
    },
    {
        "address": "0x16Da5367658686fFD5f0c6582a5049767C7096b2"
    },
    {
        "address": "0x414C0454a6D804dcfa50cD5b4BffA1B0e3980355"
    },
    {
        "address": "0x42c4F664753a23DC99D1e260821b2C33510f18FC"
    },
    {
        "address": "0x0c65e4C5DF175D113Db8062C42145FaDB7DA3164"
    },
    {
        "address": "0x3390049eC1E36672f4c9454c58316e8bCa02f182"
    },
    {
        "address": "0xf5d17BD89dc090610678147C8928Ea39bA541596"
    },
    {
        "address": "0xD5c1fcEDEdB6c807bAb16537F2db95c6877481d8"
    },
    {
        "address": "0x2592170E08cb47f03B2f41Eb1Dd7682FDc30D03a"
    },
    {
        "address": "0x0c923804Bc61B3ce3Cdf2ED811e196B42f0c5014"
    },
    {
        "address": "0x15b5d0Ff0E97aa73D0872B49Dbd08793D08435F0"
    },
    {
        "address": "0xe2E69f03eC03Daba9a3354a70669440591E9856f"
    },
    {
        "address": "0x195FC6bD0FeF683870e8370af3B3Cd8beF910709"
    },
    {
        "address": "0xc685E77aE88d67CdD8CAa1Fd5D830d9BA711c91F"
    },
    {
        "address": "0x9D18Fb05F16481fd03D70410dD0be4D3E18e849B"
    },
    {
        "address": "0xE74F1b6e3388b9B33cf374E9e69acA6Faf52721B"
    },
    {
        "address": "0x269A76Fc911F46601D976f40B300721F544D55d8"
    },
    {
        "address": "0xdd2C0d552e9e1b15f98097466fd6C455daeFe3b6"
    },
    {
        "address": "0x5D16cA2123aEc40f1dc8E98FaBb413BD28Da1a90"
    },
    {
        "address": "0x0Ea3bB5Da858e58F215C94AB98b3e6864B430be5"
    },
    {
        "address": "0x55ED291Cf774fB972fB3612B4d911Df8a08E0cE7"
    },
    {
        "address": "0x30D46b13867eD7d1CeA3728CBaa6320bE7267074"
    },
    {
        "address": "0x4aA110F810772160A202891883b71E2B559Ae406"
    },
    {
        "address": "0xE5859D74617c562478541864761c109863780A1a"
    },
    {
        "address": "0xCc61891f03447AC8fF9f4B0290Adfbd381D986a2"
    },
    {
        "address": "0xd626A75cF1a34de8cdF0dFf5D4Fbff452E28Da89"
    },
    {
        "address": "0xc95C03E4C2F92B552Ce923dc64584fF849E1f1FA"
    },
    {
        "address": "0x28BA2c17dfb44da657d684De4d901b067015b06e"
    },
    {
        "address": "0xbf3C2E47eecAAb9c08A9eCf88B2099E92398f168"
    },
    {
        "address": "0xC983D65557dd8816C8e2f16892391922C48b8edE"
    },
    {
        "address": "0x1d146FbbE54472f6A8A32a40b98a44d1d6b8991c"
    },
    {
        "address": "0x9A8172fe0228f71eA4a2c7bE9b271978C017b1d9"
    },
    {
        "address": "0xF74f2133bEBcCE13d736E3C09c147A3fF1bdcd83"
    },
    {
        "address": "0x67924910A93e162ac0Ce170b8cC01c2726BA11D3"
    },
    {
        "address": "0x9baad60a0764654e7f60D9a40AE9c0d8804F6213"
    },
    {
        "address": "0xBf651289775043D1725251717D1c2D7D4C8dCbcc"
    },
    {
        "address": "0x62c8C6250FA4C45DaC0cfED175CE8A4F1a182420"
    },
    {
        "address": "0xc90464e3F34c67ab81D627E83D334306F3024E94"
    }
]