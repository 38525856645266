import BadlandsTitle from '../../assets/faction-titles/badlands.svg'
import BadlandsBackground from '../../assets/faction-backgrounds/badlands.webp'
import BadlandsNormal1 from '../../assets/faction-objects/0.png'
import BadlandsNormal2 from '../../assets/faction-objects/1.png'
import BadlandsNormal3 from '../../assets/faction-objects/badlands/normal/3.png'
import BadlandsNormal4 from '../../assets/faction-objects/badlands/normal/4.png'
import BadlandsDark1 from '../../assets/faction-objects/2.png'
import BadlandsDark2 from '../../assets/faction-objects/3.png'
import BadlandsDark3 from '../../assets/faction-objects/badlands/dark/3.png'
import BadlandsDark4 from '../../assets/faction-objects/badlands/dark/4.png'

import FrontierTitle from '../../assets/faction-titles/frontier.svg'
import FrontierTitle1 from '../../assets/faction-titles/frontier-1.svg'
import FrontierTitle2 from '../../assets/faction-titles/frontier-2.svg'
import FrontierBackground from '../../assets/faction-backgrounds/frontier.webp'
import FrontierNormal1 from '../../assets/faction-objects/3.png'
import FrontierNormal2 from '../../assets/faction-objects/4.png'
import FrontierNormal3 from '../../assets/faction-objects/frontier/normal/3.png'
import FrontierNormal4 from '../../assets/faction-objects/frontier/normal/4.png'
import FrontierDark1 from '../../assets/faction-objects/5.png'
import FrontierDark2 from '../../assets/faction-objects/6.png'
import FrontierDark3 from '../../assets/faction-objects/frontier/dark/3.png'
import FrontierDark4 from '../../assets/faction-objects/frontier/dark/4.png'

import GroveTitle from '../../assets/faction-titles/grove.svg'
import GroveTitle1 from '../../assets/faction-titles/grove-1.svg'
import GroveTitle2 from '../../assets/faction-titles/grove-2.svg'
import GroveBackground from '../../assets/faction-backgrounds/grove.webp'
import GroveNormal1 from '../../assets/faction-objects/7.png'
import GroveNormal2 from '../../assets/faction-objects/8.png'
import GroveNormal3 from '../../assets/faction-objects/grove/normal/3.png'
import GroveNormal4 from '../../assets/faction-objects/grove/normal/4.png'
import GroveDark1 from '../../assets/faction-objects/9.png'
import GroveDark2 from '../../assets/faction-objects/10.png'
import GroveDark3 from '../../assets/faction-objects/grove/dark/3.png'
import GroveDark4 from '../../assets/faction-objects/grove/dark/4.png'

import CitadelTitle from '../../assets/faction-titles/citadel.svg'
import CitadelTitle1 from '../../assets/faction-titles/citadel-1.svg'
import CitadelTitle2 from '../../assets/faction-titles/citadel-2.svg'
import CitadelBackground from '../../assets/faction-backgrounds/citadel.png'
import CitadelNormal1 from '../../assets/faction-objects/citadel/normal/1.png'
import CitadelNormal2 from '../../assets/faction-objects/citadel/normal/2.png'
import CitadelNormal3 from '../../assets/faction-objects/citadel/normal/3.png'
import CitadelNormal4 from '../../assets/faction-objects/citadel/normal/4.png'
import CitadelDark1 from '../../assets/faction-objects/citadel/dark/1.png'
import CitadelDark2 from '../../assets/faction-objects/citadel/dark/2.png'
import CitadelDark3 from '../../assets/faction-objects/citadel/dark/3.png'
import CitadelDark4 from '../../assets/faction-objects/citadel/dark/4.png'

interface FactionData {
  id: string
  titleImgUrl: string
  mobileTitleImgUrl: string[]
  title: string
  backgroundImgUrl: string
  description: string[]
  objects: string[]
  darkObjects: string[]
  color1: string
  color2: string
}

interface FactionsMoreData {
  badlands: FactionData
  frontier: FactionData
  grove: FactionData
  // citadel: FactionData
}

const FACTIONS_MORE_DATA: FactionsMoreData = {
  badlands: {
    titleImgUrl: BadlandsTitle,
    mobileTitleImgUrl: [BadlandsTitle],
    id: 'badlands',
    title: 'Badlands',
    backgroundImgUrl: BadlandsBackground,
    description: [
      'Challenge the Glacier Racetrack',
      'The Glacier Racetrack is a battlefield for the brave. On this ice-covered track, every race is an extreme test of courage and skill. ',
      "The cold environment and slippery track bring unprecedented challenges, and only the true champions can achieve victory here.",
      "Bring your NFT, embrace the challenge of the Glacier Racetrack, and win the glory and rewards you deserve!"
    ],
    objects: [
      BadlandsNormal1,
      BadlandsNormal2,
    ],
    darkObjects: [BadlandsDark1, BadlandsDark2],
    color1: '#F1A347',
    color2: '#FF72E0',
  },
  frontier: {
    titleImgUrl: FrontierTitle,
    mobileTitleImgUrl: [FrontierTitle1, FrontierTitle2],
    id: 'frontier',
    title: 'Frontier',
    backgroundImgUrl: FrontierBackground,
    description: [
      'Explore the Grassland Racetrack',
      'The Grassland Racetrack is a lush, vibrant land symbolizing vitality and energy.',
      'Here, feel the thrill of horses galloping across the soft grass, enjoying the perfect blend of speed and nature.',
      'Every race is full of strategy and wisdom, and only the best riders can stand out. ',
      'Join in, and every NFT holder can showcase their strength here and win generous rewards!'
    ],
    objects: [
      FrontierNormal1,
      FrontierNormal2,
    ],
    darkObjects: [FrontierDark1, FrontierDark2],
    color1: '#6BB4C0',
    color2: '#61B4FA',
  },
  grove: {
    titleImgUrl: GroveTitle,
    mobileTitleImgUrl: [GroveTitle1, GroveTitle2],
    id: 'grove',
    title: 'Grove',
    backgroundImgUrl: GroveBackground,
    description: [
      'Welcome to the Desert Racetrack ',
      'Challenge your limits and harness speed and passion on this scorching track. The Desert Racetrack is a place full of unique challenges and endless possibilities. ',
      "Whether you're a novice or a veteran, every race here will test your skills and strategy. Bring your NFT and gallop across the vast expanse of the desert, experiencing an extraordinary horse racing journey!",
    ],
    objects: [GroveNormal1, GroveNormal2],
    darkObjects: [GroveDark1, GroveDark2],
    color1: '#B24AC2',
    color2: '#68E3D5',
  },
  // citadel: {
  //   titleImgUrl: CitadelTitle,
  //   mobileTitleImgUrl: [CitadelTitle1, CitadelTitle2],
  //   id: 'citadel',
  //   title: 'Citadel',
  //   backgroundImgUrl: CitadelBackground,
  //   description: [
  //     'These are where the apex predators in Dusktopia reside',
  //     'The political and economic masters of all society: living high above the suffering of ordinary denizens, and calmly plot elevated pursuits like space colonization in absolute peace',
  //     'If you’re lucky enough to be part of it, hold on tight - everybody wants what you have',
  //   ],
  //   objects: [CitadelNormal1, CitadelNormal2, CitadelNormal3, CitadelNormal4],
  //   darkObjects: [CitadelDark1, CitadelDark2, CitadelDark3, CitadelDark4],
  //   color1: '#E4DF77',
  //   color2: '#68E3D5',
  // },
}

export default FACTIONS_MORE_DATA
