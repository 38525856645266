import { FC, useEffect, useState } from "react";
import Button from "../../components/button/button.component";
import Factions from "../../components/factions/factions.component";
import SideMenu from "../../components/side-menu/side-menu.component";
import FactionsMoreGroup from "../../components/factions-more-group/factions-more-group.component";
import "./homepage.styles.scss";
import { IoMdClose } from "react-icons/io";
import { data } from "./data";
import { useAccount } from "wagmi";
const list = data
  .map((user: { address: string | undefined }) => {
    return { ...user, points: Math.floor(Math.random() * 100) };
  })
  .sort((a, b) => {
    return b.points - a.points;
  });
async function copyTextToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text);
    console.log("Text copied to clipboard");
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
}
const Homepage: FC = () => {
  const [showToast, setShowToast] = useState(false);
  const [showToast_, setShowToast_] = useState(false);
  const [showToast__, setShowToast__] = useState(false);
  const { address } = useAccount();
  // let pointList = list;
  const randomRank = Math.floor(Math.random() * 10 + 20);
  console.log(randomRank);
  const { points } = list[randomRank];
  if (address && !list.find((l) => l.address == address)) {
    list.splice(randomRank, 1, { address, points });
  }
  return (
    <div className="homepage">
      <section className="section home" id="home">
        <div className="content">
          <p
            className="h1 light section-title"
            style={{ zIndex: 1, userSelect: "none" }}
          >
            Welcome to Golden Horse Racing
          </p>
          <p
            className="h3 light section-title"
            style={{ zIndex: 1, userSelect: "none" }}
          >
            an NFT-based horse racing game
          </p>
          <div className="h3 light" style={{ zIndex: 1, userSelect: "none" }}>
            TotalSupply: 1001
          </div>
          <div className="h4 light" style={{ zIndex: 1, userSelect: "none" }}>
            MintPrice: 0.07 ETH
          </div>
          <div className="button-container" style={{ position: "relative" }}>
            <Button onClick={() => setShowToast(!showToast)} type="white">
              Mint
            </Button>
            {showToast ? (
              <div
                className="absolute border border-black text-xs px-4 py-2 w-60 flex items-center justify-between rounded-0"
                style={{
                  top: "5rem",
                  right: "-3rem",
                  backgroundColor: "white",
                }}
              >
                <p>You are late!Minted Out!</p>
                <IoMdClose
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowToast(!showToast)}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="ellipse-1" />
        <div className="ellipse-2" />
        <SideMenu currentSection="home" />
      </section>
      <section className="factions section" id="lands">
        <div className="ellipse ellipse-1" />
        <div className="ellipse ellipse-2" />
        <div className="section-content">
          <div className="title-container">
            <p
              className=" h3 section-title2 light"
              style={{ userSelect: "none" }}
            >
              Each NFT gives its holder a chance to participate in weekly races.
            </p>
            <p
              className=" h3 section-title2 light"
              style={{ userSelect: "none" }}
            >
              Players can purchase additional entries for increased chances of
              winning.
            </p>
            <p
              className=" h3 section-title2 light"
              style={{ userSelect: "none" }}
            >
              Compete weekly for rankings and rewards, which reset every Monday.
            </p>
            <p
              className=" h3 section-title2 light"
              style={{ userSelect: "none" }}
            >
              Top performers receive special rewards at the end of each season.
            </p>
            <p
              className=" h3 section-title2 light"
              style={{ userSelect: "none" }}
            >
              Players benefit from profit sharing through funds spent on
              additional entries.
            </p>
          </div>
          <Factions />
        </div>
        <SideMenu currentSection="lands" />
        <FactionsMoreGroup />
      </section>
      <section className="dashboard section" id="dashboard">
        <div className="ellipse ellipse-1" />
        <div className="ellipse ellipse-2" />
        <div
          className="section-content"
          style={{
            height: "80vh",
            overflow: "hidden",
            zIndex: 9,
          }}
        >
          <div className="buttons flex absolute" style={{ left: "10px" }}>
            <div
              className="button-container"
              style={{ position: "relative", marginRight: "10px" }}
            >
              <Button
                type="transparent"
                onClick={() => setShowToast_(!showToast_)}
              >
                Check In
              </Button>
              {showToast_ ? (
                <div
                  className="absolute border border-black text-xs px-4 py-2 w-60 flex items-center justify-between rounded-0"
                  style={{
                    top: "5rem",
                    right: "-6rem",
                    backgroundColor: "white",
                  }}
                >
                  <p>Check in success!</p>
                  <IoMdClose
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowToast_(!showToast_)}
                  />
                </div>
              ) : null}
            </div>
            <div className="button-container" style={{ position: "relative" }}>
              <Button
                type="transparent"
                onClick={() => {
                  if(address) {
                    copyTextToClipboard(`https://golden-horse.org/?invite=${address}`)
                    setShowToast__(!showToast__);
                  }
                }}
              >
                Invite
              </Button>
              {showToast__ ? (
                <div
                  className="absolute border border-black text-xs px-4 py-2 w-60 flex items-center justify-between rounded-0"
                  style={{
                    top: "5rem",
                    right: "-3rem",
                    backgroundColor: "white",
                  }}
                >
                  <p>Copied to clipboard!</p>
                  <IoMdClose
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowToast__(!showToast__)}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "12px",
              paddingTop: "12px",
            }}
          >
            <h4 style={{ paddingBottom: "12px", paddingTop: "12px" }}>
              Points Dashboard
            </h4>
          </div>
          <div
            className="dashboard-table"
            style={{ height: "100%", overflowY: "scroll", zIndex: 9 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "400px",
                padding: "0 10px",
                color: "",
              }}
            >
              <div style={{ marginRight: "10px", width: "40px" }}>Rank</div>
              <div
                style={{
                  marginRight: "auto",
                }}
              >
                User
              </div>
              <div>Points</div>
            </div>
            {list.map((user, i) => {
              return (
                <div
                  key={user.address}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: user.address === address ? "blue" : "white",
                    width: "400px",
                    padding: "0 10px",
                  }}
                >
                  <div style={{ marginRight: "10px", width: "40px" }}>
                    {i + 1}
                  </div>
                  <div
                    style={{
                      marginRight: "auto",
                    }}
                  >
                    {user?.address?.slice(0, 4)}...{user?.address?.slice(4, 8)}
                  </div>
                  <div>{user.points}</div>
                </div>
              );
            })}
          </div>
        </div>
        <SideMenu currentSection="dashboard" />
        <FactionsMoreGroup />
      </section>
    </div>
  );
};

export default Homepage;
